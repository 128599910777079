import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [9,[2]],
		"/(app)/admin": [10,[2,3]],
		"/(app)/admin/settings": [11,[2,3]],
		"/(app)/assistants": [12,[2,4]],
		"/auth": [53],
		"/auth/reset-password": [54],
		"/(app)/c": [13,[2]],
		"/(app)/c/[id]": [14,[2]],
		"/(app)/dashboard": [15,[2]],
		"/(app)/document_generator": [16,[2,5]],
		"/(app)/document_generator/chat": [17,[2,5]],
		"/error": [55],
		"/(app)/meetings": [18,[2,6]],
		"/(app)/quick_actions": [19,[2,7]],
		"/s/[id]": [56],
		"/(app)/tutorials": [20,[2]],
		"/(app)/workers": [21,[2]],
		"/(app)/workers/[id]": [22,[2]],
		"/(app)/workspace": [23,[2,8]],
		"/(app)/workspace/documents": [24,[2,8]],
		"/(app)/workspace/models": [25,[2,8]],
		"/(app)/workspace/models/create": [26,[2,8]],
		"/(app)/workspace/models/edit": [27,[2,8]],
		"/(app)/workspace/modules": [28,[2,8]],
		"/(app)/workspace/modules/create": [29,[2,8]],
		"/(app)/workspace/modules/edit": [30,[2,8]],
		"/(app)/workspace/playground": [31,[2,8]],
		"/(app)/workspace/prompts": [32,[2,8]],
		"/(app)/workspace/prompts/create": [33,[2,8]],
		"/(app)/workspace/prompts/edit": [34,[2,8]],
		"/(app)/workspace/quick-action-categories": [35,[2,8]],
		"/(app)/workspace/quick-action-categories/create": [36,[2,8]],
		"/(app)/workspace/quick-action-categories/edit": [37,[2,8]],
		"/(app)/workspace/quick-actions": [38,[2,8]],
		"/(app)/workspace/quick-actions/create": [39,[2,8]],
		"/(app)/workspace/quick-actions/edit": [40,[2,8]],
		"/(app)/workspace/smart-contexts": [41,[2,8]],
		"/(app)/workspace/smart-contexts/create": [42,[2,8]],
		"/(app)/workspace/smart-contexts/edit": [43,[2,8]],
		"/(app)/workspace/tools": [44,[2,8]],
		"/(app)/workspace/tools/create": [45,[2,8]],
		"/(app)/workspace/tools/edit": [46,[2,8]],
		"/(app)/workspace/tutorials": [47,[2,8]],
		"/(app)/workspace/tutorials/create": [48,[2,8]],
		"/(app)/workspace/tutorials/edit": [49,[2,8]],
		"/(app)/workspace/workers": [50,[2,8]],
		"/(app)/workspace/workers/create": [51,[2,8]],
		"/(app)/workspace/workers/edit": [52,[2,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';